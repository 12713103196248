import { parseISO, getTime } from "date-fns";
import humanizeList from "humanize-list";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";

import type { DisplayName, SearchResultItem } from './models/search-result-item';

export const mq = {
	small: "screen and (min-width: 35em)",
	medium: "screen and (min-width: 60em)",
	large: "screen and (min-width: 76em)",
	xlarge: "screen and (min-width: 90em)",
	fullsize: "screen and (min-width: 120em)"
};

export enum State {
	Initial = "initial",
	Loading = "loading",
	Success = "success",
	Error = "error"
};

export enum Gender {
	Female = "female",
	Male = "male"
};

export enum OneTrustGroups {
	Essential = "C0001",
	Performance = "C0002",
	Functional = "C0003",
	Targeting = "C0004"
}

export function isNullOrEmpty(value: string | null | undefined): value is "" | null | undefined {
	return value === null || value === undefined || value === "";
}

export function idSafe(value: string) {
	return value.replace(/([^a-zA-Z0-9_-])+/, "__");
}

export function timestampFromDate(date: string): number {
	return Math.floor(getTime(parseISO(date)) / 1000);
}

export function wasDelegatedFrom(event: Event, selector: string): boolean {
	return getDelegatedElement(event, selector) != null;
}

export function getDelegatedElement(event: Event, selector: string): Element | null {
	let context = event.target as Element | null;
	while (context && !context.matches(selector) && context !== event.currentTarget) {
		context = context.parentElement;
	}
	if (context === event.currentTarget) {
		return null;
	}
	return context;
}

const locationOrder: Record<string, number> = {
	"Thun": 1,
	"Aarau": 2,
	"Rapperswil": 3
};
export function getLocationNames(item: SearchResultItem) {
	let list: { name: string, order: number }[] = [];
	if (item.location) {
		let value = extractValueFromDisplayName(item.location.displayName);
		list.push({ name: value, order: locationOrder[value] });
	}
	if (item.otherLocations) {
		list.push(...item.otherLocations.map(l => {
			let value = extractValueFromDisplayName(l.displayName);
			return { name: value, order: locationOrder[value] };
		}));
	}

	let ordered = orderBy(uniqBy(list, x => x.name), ["order", "name"]);

	return humanizeList(ordered.map(x => x.name), {
		conjunction: "und"
	});
}

export function extractValueFromDisplayName(displayName: DisplayName | null | undefined): string {
	let value = "";
	if (Array.isArray(displayName)) {
		return displayName[0].value ?? "";
	} else {
		return displayName?.iv ?? "";
	}
}

export type Mutable<T> = {
	-readonly [P in keyof T]: T[P];
};

export function documentReady(handler: () => void) {
	if (document.readyState != "loading") {
		handler();
	} else {
		document.addEventListener("DOMContentLoaded", handler);
	}
}
