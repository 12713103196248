import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";
import debounce from "lodash/debounce";
import queryString from "query-string";
import { liteClient, type LiteClient } from "algoliasearch/lite";
import { Key } from "ts-key-enum";
import insightsClient from "search-insights";

import { render } from "./search-box.html";
import { type SearchResultItem } from "../models/search-result-item";
import { isNullOrEmpty, OneTrustGroups } from "../utilities";
import { CourseFilterStore } from "../filter/course-filter-storemodule";

enum Size {
	Large = "large",
	Normal = "normal"
}

@Component({
	render
})
export class SearchBox extends Vue {
	private readonly store = new CourseFilterStore();
	private searchClient: LiteClient;

	@Prop({ required: true }) resultPageUrl: string;
	@Prop({ default: Size.Normal }) size: Size;
	@Prop({ default: "Suchen nach …" }) placeholder: string;
	@Prop() textfieldClass: string;
	@Prop() apiKey: string;

	isInitialized: boolean = false;
	isBusy: boolean = false;
	query: string = "";
	results: SearchResultItem[] = [];
	queryId: string | null = null;

	get textfieldElement(): HTMLElement | null {
		var component = this.$refs.textfield as any;
		if (component == null) {
			return null;
		}
		return component.$el as HTMLElement;
	}
	get menuComponent(): any {
		return this.$refs.menu;
	}

	get menuWidth(): number {
		return this.textfieldElement ? this.textfieldElement.clientWidth : 300;
	}
	get menuVerticalOffset(): number {
		return this.size === Size.Large ? 1 : 28;
	}

	get isMenuOpen(): boolean {
		return !isNullOrEmpty(this.query) || this.results.length > 0;
	}

	mounted() {
		this.searchClient = liteClient(import.meta.env.VITE_algoliaAppId, this.apiKey);
		insightsClient("init", {
			appId: import.meta.env.VITE_algoliaAppId!,
			apiKey: this.apiKey,
			useCookie: OnetrustActiveGroups?.includes(OneTrustGroups.Performance) ?? false,
			cookieDuration: 86400000 // 24 hours
		});
		window.addEventListener("OneTrustGroupsUpdated", this.onConsentChanged);
		this.isInitialized = true;
	}

	beforeDestroy() {
		window.removeEventListener("OneTrustGroupsUpdated", this.onConsentChanged);
	}

	onConsentChanged(e: CustomEvent<string>) {
		insightsClient("init", {
			partial: true,
			useCookie: e.detail.includes(OneTrustGroups.Performance) ?? false
		});
	}

	@Watch("isMenuOpen")
	onIsMenuOpenChanged() {
		if (this.isMenuOpen) {
			// send one arrow-down event to auto-select "goto results"
			//this.menuComponent.changeListIndex(new KeyboardEvent("keydown", { key: "ArrowDown", code: "ArrowDown", keyCode: 40, which: 40 } as any));
		}
	}

	@Watch("query")
	onQueryChanged(value: string) {
		if (!isNullOrEmpty(value)) {
			this.searchDebounced(value);
		} else {
			this.results = [];
		}
	}

	readonly searchDebounced = debounce(this.search, 350);
	async search(query: string) {
		this.isBusy = true;

		let response = await this.store.autocompleteQuery({
			query,
			client: this.searchClient
		});
		this.results = response.hits;
		this.queryId = response.queryID!;

		try {
			_paq.push(["trackSiteSearch", query, response.hits.length]);
		} catch { }

		this.isBusy = false;
	}

	onKeyDown(e: KeyboardEvent) {
		if (e.key === Key.Enter) {
			this.navigateToResults(this.query);
			return;
		}

		//this.menuComponent.changeListIndex(e);
	}

	navigateToResults(query: string) {
		this.store.resetFilter();
		this.store.setFilter({ query });
		location.href = this.resultPageUrl + "#!" + queryString.stringify({ s: this.store.urlHash });
	}
	navigateToCourse(item: SearchResultItem, index: number) {
		let params = {
			eventName: "Course Clicked",
			index: this.store.indexName,
			queryID: this.queryId!,
			objectIDs: [item.objectID],
			positions: [index + 1],
		};
		insightsClient("clickedObjectIDsAfterSearch", params);

		location.href = item.url;
	}

	getDescriptionSnippet(item: SearchResultItem): string {
		if (item._snippetResult && item._snippetResult.description) {
			return item._snippetResult.description.value;
		} else {
			return item.description;
		}
	}

	getImageUrl(item: SearchResultItem) {
		if (item.imageUrl == null) {
			return "";
		}
		var parsed = queryString.parseUrl(item.imageUrl);
		parsed.query.width = "500";
		parsed.query.height = "300";
		return queryString.stringifyUrl(parsed);
	}
}
