import tippy from "tippy.js";
import MoveTo from "moveto";
import { Fancybox } from "@fancyapps/ui";
import { nanoid } from "nanoid";

import { CourseAccordion } from "./course-accordion";

const IsExpanded = "is-expanded";
const HasExpanded = "has-expanded";
const moveTo = new MoveTo();

export class Page {
	constructor() {
		this.setupNavigation();
		this.setupCourseAccordion();
		this.setupAnchorScrolling();

		Fancybox.bind(".is-zoomable");

		tippy("[data-tippy-content]", {
			allowHTML: true,
			appendTo: "parent",
		});
		tippy("[data-tippy-element]", {
			allowHTML: true,
			appendTo: "parent",
			content: reference => {
				let tooltip = document.querySelector<HTMLElement>(reference.getAttribute("data-tippy-element")!);
				tooltip?.style.removeProperty("display");
				return tooltip ?? "";
			},
		});

		for (let element of document.querySelectorAll(".courseBookingAction")) {
			element.addEventListener("click", e => {
				let courseId = element.getAttribute("data-courseid");
				let bsdId = element.getAttribute("data-bsdid");
				let fullCourseName = element.getAttribute("data-coursefullname");
				let price = parseFloat(element.getAttribute("data-courseprice") ?? "0");

				try {
					gtag("event", "begin_checkout", {
						transaction_id: nanoid(),
						items: [{ item_id: bsdId ?? courseId, item_name: fullCourseName }],
						currency: "CHF",
						value: price,
						system: "BSD",
					});
				} catch { }

				let paqEventName = element.classList.contains("courseBookingAction--waitlist") ? "Auf Warteliste eingetragen" : "Kursanmeldung begonnen";
				try {
					_paq.push(["trackEvent", "Kurse", paqEventName, fullCourseName]);
				} catch { }
			});
		}

		for (let element of document.querySelectorAll("a[href='#OpenOneTrustPreferenceCenter']")) {
			element.addEventListener("click", e => {
				e.preventDefault();
				OneTrust.ToggleInfoDisplay();
			});
		}
	}

	//setupTooltips() {
		//var tooltippedElements = document.querySelectorAll("[data-tooltip]") as NodeListOf<HTMLElement>;
		//for (let element of tooltippedElements) {
		//	var text = element.getAttribute("data-tooltip");
		//	var placement = element.getAttribute("data-tooltip-placement") || "bottom";
		//	if (text) {
		//		new Tooltip(element, {
		//			title: text,
		//			placement: placement as any
		//		});
		//	}
		//}
	//}

	setupCourseAccordion() {
		let courseAccordionElement = document.querySelector(".course-modules-accordion");
		if (courseAccordionElement != null) {
			new CourseAccordion();
		}
	}

	setupAnchorScrolling() {
		if (window.location.hash.length > 0 && !window.location.hash.startsWith("#!")) {
			var targetElement = document.querySelector(window.location.hash) as HTMLElement;
			if (targetElement?.classList.contains("btn")) {
				targetElement.onclick?.call(targetElement, new MouseEvent("click"));
			}
		}

		for (let element of document.querySelectorAll(".overviewModule a[href^='#']")) {
			element.addEventListener("click", function (e) {
				let anchor = e.target as HTMLElement;
				let targetSelector = anchor.getAttribute("href") || "";
				let target = document.querySelector(targetSelector) as HTMLElement | null;
				if (target != null) {
					moveTo.move(target, { duration: 400 });
					e.preventDefault();
				}
			});
		}
	}

	setupNavigation() {
		let navLinks = Array.from(document.querySelectorAll(".navigation--header .nav-link.level-1")) as HTMLElement[];
		let navLists = Array.from(document.querySelectorAll(".navigation--header .nav-list.level-2")) as HTMLElement[];

		window.addEventListener("click", function (e) {
			let target = e.target as HTMLElement;
			if (target.closest(".nav-list")) {
				return;
			}
			navLists.forEach(e => e.classList.remove(IsExpanded));
			navLinks.forEach(e => e.classList.remove(HasExpanded));
		});

		for (let link of navLinks) {
			link.addEventListener("click", function (e) {
				let trigger = e.target as HTMLElement;
				let list = trigger.parentElement!.querySelector(".nav-list") as HTMLElement;

				if (list != null) {
					navLists.filter(e => e !== list).forEach(e => e.classList.remove(IsExpanded));
					navLinks.filter(e => e !== trigger).forEach(e => e.classList.remove(HasExpanded));

					trigger.classList.toggle(HasExpanded);
					list.classList.toggle(IsExpanded);

					if (list.classList.contains(IsExpanded)) {
						e.stopPropagation();
					}

					e.preventDefault();
				}
			});
		}

		let toggle = document.getElementById("navToggle");
		if (toggle != null) {
			toggle.addEventListener("click", e => {
				let nav = document.querySelector("nav");
				nav?.classList.toggle("is-open");
			});
		}
	}
}
